.sendButton {
  min-width: 140px;
  background-color: #1267c1;
  color: #fff;
  border-radius: 0px;
  margin-top: 20px;
  height: 40px;
  border: unset;
}

.sendButton:hover {
  background-color: #018fd7;
  color: #fff;
  border: unset;
  min-width: 140px;
}

.sendButton:active {
  border: none;
  background: #1267c1;
  color: #fff;
}

.sendButton:focus {
  box-shadow: unset !important;
}

.sendButton:focus {
  border: none;
  background: #1267c1;
  color: #fff;
}

.ProceedButton {
  min-width: 140px;
  background-color: #157347;
  color: #fff;
  border-radius: 0px;
  margin-top: 20px;
  height: 40px;
  border: unset;
}

.ProceedButton:hover {
  background-color: #157347;
  color: #fff;
  border: unset;
  min-width: 140px;
}

.ProceedButton:active {
  border: none;
  background: #157347 !important;
  color: #fff;
}

.ProceedButton:focus {
  box-shadow: unset !important;
}

.ProceedButton:focus {
  border: none;
  background: #157347;
  color: #fff;
}

.lightButton {
  min-width: 140px;
  background: #e9ecef;
  color: #3e3e3e;
  border-radius: 0px;
  margin-top: 20px;
  height: 40px;
  border: unset;
}

.lightButton:hover {
  background: #f5f5f5 !important;
  color: gray !important;
  min-width: 140px;
  border: unset;
}

.lightButton:active {
  box-shadow: unset !important;
  background: #e9ecef !important;
  color: #3e3e3e;
}

.lightButton:focus {
  box-shadow: unset !important;
  background: #e9ecef !important;
  color: #3e3e3e;
}

.resendButton {
  background-color: gray !important;
  border-radius: 0;
  min-width: 140px;
  height: 40px;
  margin-top: 20px;
  text-align: "center";
  border: unset;
}

.resendButton:hover {
  background-color: gray !important;
  border: none;
  opacity: 0.9;
  min-width: 140px;
  border: unset;
}

.resendButton:active {
  background-color: gray !important;
  box-shadow: unset !important;
}

.resendButton:focus {
  box-shadow: unset !important;
}

.right-ten {
  margin-right: 10px;
}

.left-ten {
  margin-left: 10px;
}

.outline:hover {
  background: #1267c1;
}

.outline {
  min-width: 48%;
  border-radius: 0;
  border-color: #1267c1;
  color: #1267c1;
}

@media only screen and (max-width: 400px) {
  .sendButton,
  .resendButton,
  .lightButton,
  .ProceedButton {
    min-width: 110px;
  }
  .sendButton:hover,
  .resendButton:hover,
  .lightButton:hover,
  .ProceedButton:hover {
    min-width: 110px;
  }
}

@media only screen and (max-width: 327px) {
  .sendButton,
  .resendButton,
  .lightButton,
  .ProceedButton {
    min-width: unset;
  }
  .sendButton:hover,
  .resendButton:hover,
  .lightButton:hover,
  .ProceedButton:hover {
    min-width: unset;
  }
}

@font-face {
  font-family: "swiss-bt";
  src: local("swiss-bt"), url("../../font/swiss-bt.ttf") format("truetype");
  font-weight: bold;
}

.btn-check:active+.btn:focus,
.btn-check:checked+.btn:focus,
.btn.active:focus,
.btn.show:focus,
.btn:active:focus {
  box-shadow: unset;
}

.titleHead {
  margin-bottom: 50px;
}

.relative {
  position: relative;
}

.teamAccodian .accordion-button {
  padding: 10px !important;
}

.teamAccodian .accordion-body {
  padding: 0 10px !important;
}

.accordion-button:focus {
  box-shadow: unset;
}

.teamAccodian .accordion-item {
  border: 1px solid #f6f6f6 !important;
  margin-bottom: 20px !important;
}

.teamAccodian .accordion-button:not(.collapsed) {
  color: #000 !important;
  background-color: #fff !important;
  box-shadow: unset !important;
}

.nav-side-logo {
  font-size: 30px;
  cursor: pointer;
  display: none;
}

.home-header .navbar-logo {
  height: 71px;
  width: auto;
}

.offcanvas {
  width: 85% !important;
  font-family: "Red Hat Display", sans-serif;
}

.sidebar-body button.accordion-button.collapsed {
  padding: 0;
  color: #1268c0;
}

.sidebar-body .contact-us {
  color: #1268c0;
}

.sidebar-body .accordion-item {
  border: unset;
}

.sidebar-body .accordion-button::after {
  background-image: var(--bs-accordion-btn-active-icon);
}

.sidebar-body .accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
}

.sidebar-body .accordion-button:not(.collapsed) {
  color: #1268c0 !important;
  box-shadow: unset !important;
  background: unset !important;
  padding: 0 !important;
}
.tab {
  margin-left: 5%;
}
.tab-without-style{
  margin-left: 5%;
  list-style: none;
}
.sidebar-body .accordion-button:focus {
  box-shadow: unset;
}

.sidebar-body .accordion-header {
  padding: 10px 0px;
}

.sidebar-body .accordion-header button {
  font-size: 14px;
}

.sidebar-link a {
  font-size: 14px;
}

.sidebar-body .accordion-body {
  padding: 0;
}

.offcanvas-header {
  border-bottom: 1px solid #e2e2e2 !important;
}

.sidebar-link .dropdown-item {
  padding: 5px 0px 5px 10px;
}

.main-top-header .dropdown-item {
  color: gray !important;
}

.sidebar-link .dropdown-item:focus,
.dropdown-item:hover {
  color: #000 !important;
}

.lable-box {
  height: 25px;
  width: 25px;
}

.lable-box-1 {
  background: #1268c0;
}

.lable-box-2 {
  background: #67b7dc;
}

.productInfoRight {
  margin-left: 30px;
  width: 100%;
  align-self: center;
}

.testImg {
  width: 100%;
}

.imgBox {
  width: 100%;
  margin: auto;
}

.marginBottom-30 {
  margin-bottom: 30px;
}

.marginTop-30 {
  margin-top: 30px;
}

.marginTop-20 {
  margin-top: 20px;
}

.nestLink .dropdown-toggle::after {
  content: unset !important;
}

.font-14 {
  font-size: 14px;
}

.capitalLi {
  list-style: lower-alpha;
}

.home-header .navbar-brand {
  padding: 0px;
  margin: 0;
}

.home-header a.nav-links.active.nav-link {
  color: #fff;
  background-color: #1368bc;
  border-radius: 5px;
  font-family: "Red Hat Display", sans-serif;
}

.right-border-btn {
  border-right: 1px solid #fff !important;
  padding-top: 3px;
}

.home-header .navbar-toggler-icon::before {
  content: " ";
  position: absolute;
  top: -9px;
  background: #018fd7;
  width: 25px;
  height: 3px;
  border-radius: 50px;
  left: 0;
}

.home-header .nav-links {
  color: #252525;
  font-size: 14px;
  padding: 0px 0px !important;
  font-weight: normal;
  font-family: "Red Hat Display", sans-serif;
  position: relative;
}

.home-header {
  box-shadow: unset;
  position: unset !important;
  padding-top: 0;
}

.drop-section {
  text-align: end;
}

.navBar {
  background-color: #fff;
  font-family: "Roboto", sans-serif;
  width: 100%;
  z-index: 99;
  top: 0;
}

.scrolled {
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
}

a {
  text-decoration: none;
  color: #000000;
}

a:hover {
  color: #222222;
}

.partnerDropdown {
  font-size: 13px;
}

.drop-section .dropdown #dropdown-basic-button {
  padding: 0px 10px;
  border-radius: 0;
  border: none;
  background: #1268c0;
  margin-top: 0px;
  font-size: 12px;
  font-family: "Red Hat Display", sans-serif;
}

.customer-button:hover {
  color: #fff;
}

.contact-us {
  align-items: center;
  display: flex;
  color: #252525;
  padding-right: 0px !important;
}

.contact-us:focus {
  box-shadow: unset;
  background-color: #fff !important;
}

.menuDropDown {
  margin: 5px;
}

.menuDropDown .dropdown {
  width: 100%;
}

.menuDropDown button {
  background: #fff !important;
  border: none;
  color: #252525;
  font-size: 14px;
  padding: 0px 0px 0px 26px !important;
  font-weight: normal;
  font-family: "Red Hat Display", sans-serif;
}

.menuDropDown .loginLink {
  padding: 0px 0px 0px 26px !important;
}

.contact-drop-class {
  align-self: center;
}

.menuDropDown button:hover {
  color: #252525;
}

.menuDropDown .dropdown-menu[data-bs-popper] {
  top: 100%;
  right: 0;
  margin-top: var(--bs-dropdown-spacer);
  font-size: 13px;
  border: none;
  border-radius: 0;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  left: unset;
}

.main-top-header .dropdown-menu.show {
  border: none;
  border-radius: 0;
}

.menuDropDown button:focus {
  color: #252525;
}

#dropdown-custom-1 {
  width: -webkit-fill-available !important;
  text-align: left !important;
  position: relative !important;
}

.right-carret {
  position: absolute;
  right: -3px;
  top: 12px !important;
  right: 7px;
  top: 13px;
  color: #252525;
  font-size: 10px;
}

.customer-button {
  padding: 0px 10px;
  border-radius: 0;
  border: none;
  background: #1268c0;
  margin-top: 3px;
  font-size: 12px;
  color: #fff;
  font-family: "Red Hat Display", sans-serif;
}

.main-top-header {
  z-index: 999;
  /* position: relative; */
  position: sticky;
  top: 0;
  background: #fff;
  border-bottom: 1px solid #e2e2e2;
  transition: 0.5s;
}

.policyUl {
  margin-left: 30px;
}

.main-top-header .navBar {
  padding-top: 10px;
  padding-bottom: 10px;
}

.shadowColl {
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
}

.reportDate {
  background: #f3f3f3;
  padding: 5px 30px;
}

.yearReport a {
  color: #6174b6 !important;
}

.yearReport {
  margin-bottom: 0;
}

.reportDate a {
  color: #6174b6;
}

.reportDateMain {
  flex-wrap: wrap;
  gap: 10px;
}

.colHeader {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.returnRightIcon {
  height: 17px;
  width: 17px;
  align-self: center;
}

.colBody {
  padding: 20px;
  border-top: 1px solid #dadada;
}

.colHeader p {
  padding: 0;
  margin: 0;
}

#dropdown-custom-1 {
  font-size: 13px;
  padding: 5px 25px 5px 17px !important;
}

#dropdown-custom-1:hover {
  background: #e9ecef !important;
  border-radius: 0;
}

.tableAnchor {
  color: #337ab7;
  text-decoration: underline;
  cursor: pointer;
}

.nestLink {
  padding: 0 !important;
  font-size: 13px;
}

.drop-section {
  display: flex;
  background: #1268c0;
  padding: 2px 0px;
  margin-left: auto;
  width: fit-content;
  display: flex;
}

.slick-next:before,
.slick-prev:before {
  font-size: 25px;
}

/* Home-footer */

.home-footer {
  padding: 50px 0;
  text-align: center;
  font-family: "Red Hat Display", sans-serif;
  position: relative;
}

.seperator {
  width: 75%;
  height: 1px;
  background-color: #ccc;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  right: 0;
}

.seperator-canc {
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin-top: 8px;
  margin-bottom: 18px;
}

.home-footer p {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

.heading-text {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 30px;
}

.sub-heading-text {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
}

.home-footer p {
  font-size: 14px;
}

.gmail {
  color: #1369c0;
  white-space: nowrap;
}

.bottom-footer {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #f6f6f6;
  font-family: "Red Hat Display", sans-serif;
}

.right-icon {
  font-size: xx-large;
  margin-right: 10px;
}

.bottom-footer h6 {
  margin: 0;
  padding: 0;
}

.copyRight {
  font-size: 14px;
}

.footer-links a {
  font-weight: 700;
  color: #337ab7;
  text-decoration: none;
  white-space: nowrap;
}

/* Download Section */

.downloads {
  background: #f6f6f6;
  font-family: "Red Hat Display", sans-serif;
}

.downloads p {
  font-size: 14px;
}

.section p {
  font-size: 14px;
}

.section a {
  font-size: 14px;
}

.home-main-heading {
  font-size: 25px;
  color: #000;
  font-weight: 900;
  font-family: "Red Hat Display", sans-serif;
}

.bv-table-description li {
  font-size: 14px;
}

.accordion-body li {
  font-size: 14px;
}

.accordion-body table {
  font-size: 14px;
}

.bv-table {
  font-size: 14px;
}

.brandTable {
  font-size: 14px;
}

.lspsTbl {
  font-size: 14px;
}

.home-main-heading-sub {
  font-size: 20px;
  color: #000;
  font-weight: 900;
  font-family: "Red Hat Display", sans-serif;
}

.aboutImg {
  margin: auto;
  display: block;
}

.sub-info-text {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  font-family: "Red Hat Display", sans-serif;
}

.home-view-more {
  background: #0360ad;
  color: #fff;
  border: unset;
  padding: 5px 15px;
  border-radius: 5px;
  font-weight: 700;
  font-family: "Red Hat Display", sans-serif;
}

.download-img {
  width: 15px;
  height: 15px;
  align-self: center;
  margin-left: 20px;
}

.home-view-more-new {
  display: none;
}

.user-name {
  font-size: 20px;
  color: #949494;
  margin-top: 10px;
  margin-bottom: 0;
  padding-bottom: 0;
}

.testimonial {
  padding-top: 50px;
  padding-bottom: 50px;
  font-family: "Red Hat Display", sans-serif;
}

.testimonial-responsive {
  display: none;
}

.user-name-responsive {
  font-size: 14px;
  margin-right: 10px;
}

.product {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  border-radius: 5px;
  padding: 0px 20px;
}

.product img {
  padding-top: 62px;
  padding-bottom: 32px;
  height: 157px;
  width: auto;
}

.product h6 {
  margin-bottom: 0px;
  padding-bottom: 25px;
  font-weight: 700;
}

.loan-box {
  background: #f4f4f4;
  padding: 20px 43px;
  position: relative;
  z-index: -1;
  height: 112px;
  width: 93%;
  margin-left: auto;
  font-family: "Red Hat Display", sans-serif;
}

.loan-box p {
  font-size: 14px;
}

.loan-box h4 {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 0px !important;
}

.loan-box-main h1 {
  font-size: 124px;
  color: #a3bad8;
  font-weight: 900;
  margin-bottom: 0;
  -webkit-text-stroke: 3px #fff;
  position: relative;
  line-height: 1;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  font-family: "swiss-bt";
  opacity: 0.7;
}

.loan-box-main {
  display: flex;
  position: relative;
  width: 100%;
}

.loan-slider .slick-next,
.loan-slider .slick-prev,
.ioan-slider .slick-arrow {
  display: none !important;
}

.loan-slider .slick-dots li button:before {
  background: #0360ad;
  height: 4px;
  width: 26px;
  color: unset;
  content: " ";
  margin-top: 15px;
}

.ResponsiveLoanJourney {
  display: none;
}

.home-slider {
  height: 500px;
  margin-bottom: 70px;
}

.home-slider .banner-img {
  height: 500px;
  width: auto;
  margin-left: auto;
  margin-right: auto;
}

.home-slider .slick-arrow {
  display: none !important;
}

.home-slider .slick-dots {
  bottom: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  position: absolute;
}

.home-slider .slick-dots li button:before {
  background: #0360ad;
  height: 4px;
  width: 26px;
  color: unset;
  content: " ";
}

.slick-slider .slick-initialized {
  position: relative;
}

.our-product {
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  border-radius: 20px;
  width: 550px;
}

.product-slider .slick-list {
  padding: 10px;
}

.product-slider .slick-prev:before {
  content: url("../../assets/images/left.svg");
  color: #7f7f7f;
  padding: 7px;
}

.product-slider .slick-next:before {
  content: url("../../assets/images/right.svg");
  color: #7f7f7f;
  padding: 7px;
}

.product-slider .slick-next:before,
.product-slider .slick-prev:before {
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  background: #fff;
  border-radius: 5px;
}

.product-slider .slick-next,
.product-slider .slick-prev {
  top: 48%;
}

.product-slider .our-product {
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 25px;
}

.our-product h4 {
  font-size: 15px;
  font-weight: 900;
  font-family: "Red Hat Display", sans-serif;
}

.our-product p {
  font-size: 14px;
  font-family: "Red Hat Display", sans-serif;
}

.product-slider .slick-prev {
  left: -37px;
}

.cal-range-main {
  min-height: 117px;
}

.apply-slider-button {
  font-weight: 400;
}

.home-select {
  background: #f6f6f6;
  border-radius: 0;
  height: 45px;
  border: 1px solid #efefef;
  font-weight: 700;
}

.home-select:focus {
  border-color: unset;
  outline: 0;
  box-shadow: unset;
}

.range-label .form-label {
  font-weight: 700;
  align-self: center;
  font-family: "Red Hat Display", sans-serif;
  margin: 0;
  width: 100%;
}

.tenure-bar .rc-slider-mark-text {
  font-size: 1rem;
  color: #212529;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  margin-top: 10px;
}

.cal-range-main {
  min-height: 117px;
}

.mainViewCal {
  position: relative;
}

.prefix {
  position: absolute;
  top: 50%;
  font-weight: 600;
  font-size: 14px;
  padding-right: 8px;
  left: 8px;
  transform: translateY(-50%);
  border-right: 1px solid #c4bfbf;
}

.range-view {
  background: #f6f6f6;
  padding: 5px 21px;
  text-align: right;
  width: 155px;
  border: none;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;
}

.range-view h4 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;
}

.infoLable {
  font-size: 16px;
  margin-top: 10px;
}

.home-chart {
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  border-radius: 5px;
  overflow: hidden;
}

.home-chart canvas {
  padding: 15px;
}

.totalList-new {
  width: 33.33%;
  padding: 10px;
}

.payTotal-new {
  border: unset;
  background: #f6f6f6;
}

.totalList-new p {
  font-weight: 500;
}

.totalList-new h1 {
  margin-bottom: 0;
  font-size: 25px;
  font-weight: 800;
}

.totalList-new h1 span {
  font-size: 17px;
  background: unset;
  margin-right: 5px;
}

.payTotal span {
  width: 1px;
  background: #cecece;
  height: 80px;
  align-self: center;
}

.chart-title {
  text-align: center;
  margin-bottom: 0;
  margin-top: 15px;
  padding-bottom: 0;
  font-weight: 800;
}

.chartLables {
  position: absolute;
  bottom: 30px;
  left: 25px;
  right: 25px;
  width: 90%;
  justify-content: space-between;
}

.chartLables h4 {
  font-size: 14px;
  margin-bottom: 0;
  align-self: center;
  font-weight: 700;
  margin-left: 10px;
}

.cal-range-main .rc-slider-rail {
  height: 8px;
}

.cal-range-main .rc-slider-handle {
  width: 18px;
  height: 18px;
  background-color: #015fad;
  border: solid 2px #015fad;
  opacity: unset;
}

.cal-range-main .rc-slider-track {
  height: 8px;
  background-color: #015fad;
}

.cal-range-main .rc-slider-step {
  height: 6px;
}

.cal-range-main .rc-slider-dot {
  bottom: -3px;
  width: 10px;
  height: 10px;
  background-color: #015fad;
  border: unset;
  border-radius: 50%;
  cursor: pointer;
}

.cal-range-main .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  box-shadow: unset;
  border: unset;
}

.downIcon {
  position: absolute;
  top: 17px;
  right: 17px;
}

.range-counter {
  margin-top: 18px;
  margin-bottom: 20px;
}

.cal-range-main .rc-slider-rail {
  height: 8px;
}

.cal-range-main .rc-slider-handle {
  width: 18px;
  height: 18px;
  background-color: #015fad;
  border: solid 2px #015fad;
  opacity: unset;
}

.cal-range-main .rc-slider-track {
  height: 8px;
  background-color: #015fad;
}

.cal-range-main .rc-slider-step {
  height: 6px;
}

.cal-range-main .rc-slider-dot {
  bottom: -3px;
  width: 10px;
  height: 10px;
  background-color: #015fad;
  border: unset;
  border-radius: 50%;
  cursor: pointer;
}

.cal-range-main .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  box-shadow: unset;
  border: unset;
}

.range-side input[type="range"] {
  -webkit-appearance: none;
  height: 7px;
  background: #f6f6f6;
  border-radius: 5px;
  background-image: linear-gradient(#0360ad, #0360ad);
  background-repeat: no-repeat;
}

.range-side input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #0560aa;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.range-side input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.page-containt {
  background: #f8f8f8;
  padding-top: 50px;
  padding-bottom: 50px;
  font-family: "Red Hat Display", sans-serif;
}

.page-containt h3 {
  font-size: 20px;
  font-weight: 800;
}

.page-containt p {
  font-size: 16px;
  font-weight: normal;
}

.heading-section {
  background: #f6f6f6;
  padding-top: 50px;
  padding-bottom: 55px;
}

.about-section p {
  font-size: 14px;
}

.heading-section h1 {
  margin-bottom: 0px;
  position: relative;
  width: fit-content;
}

.heading-section h1:after {
  content: " ";
  position: absolute;
  bottom: -5px;
  height: 3px;
  width: 100px;
  left: 0;
  background: #1268c0;
}

.IndexAbout {
  font-family: "Red Hat Display", sans-serif;
}

.section {
  padding-top: 50px;
  padding-bottom: 50px;
  font-family: "Red Hat Display", sans-serif;
}

.section p {
  text-align: justify;
}

.business-verticals h6 {
  font-weight: 800;
}

.about-heading h4 {
  margin-bottom: 0px;
}

.about-heading {
  background: #f6f6f6;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.about-heading:before {
  content: " ";
  position: absolute;
  width: 3px;
  height: 100%;
  background: #1268c0;
  left: 0;
  top: 0;
}

.lineage-content {
  background: #f6f6f6;
}

.lineage-content .about-heading {
  background: #fff;
  text-align: center;
}

.respnsive-head-info {
  display: none;
}

.lineage-content .about-heading:after {
  content: " ";
  position: absolute;
  width: 3px;
  height: 100%;
  background: #1268c0;
  right: 0;
  top: 0;
}

.team .team-head-info h5,
.team .team-head-info h6,
.team .team-head-info p {
  margin-bottom: 5px;
}

.team-head-info {
  background: #fff;
}

.team-member-info {
  font-size: 14px;
}

.team .team-head-info h5 {
  font-size: 16px;
  font-weight: 800;
}

.team .team-head-info h6 {
  font-size: 16px;
  font-weight: 800;
}

.team .team-head-info p {
  font-size: 12px;
}

.pluse {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  font-size: x-large;
}

.team {
  background: #fff;
  transition: 0.3s;
  width: 100%;
}

.main-team {
  padding: 10px;
  border: 1px solid #f6f6f6;
  margin-bottom: 20px;
}

.cgCheck {
  font-size: 30px;
  font-weight: 800;
  color: #505050;
  margin-right: 5px;
}

.cg li .cgCheck {
  width: 2%;
}

.cg li p {
  width: 95%;
  padding-bottom: 0;
  margin-bottom: 0;
  align-self: center;
}

.cg li {
  list-style: none;
}

.cg {
  padding-left: 0;
}

.hidden-info {
  margin-top: 10px !important;
  font-size: 14px;
  color: gray;
}

.advantage Button {
  border: 1px solid #e9e9e9;
  padding: 10px;
  background: #fff;
  color: #000;
  width: 100%;
  text-align: left;
  border-radius: 0;
  font-size: 16px;
  font-weight: 700;
}

.grivance-aacodian Button {
  border: 1px solid #e9e9e9;
  padding: 10px;
  background: #fafafa !important;
  color: #000;
  width: 100%;
  text-align: left;
  border-radius: 0;
  font-size: 18px;
  font-weight: 700;
}

.advantage Button:hover {
  background: #fff;
  color: #000;
  border: 1px solid #e9e9e9;
}

.grivance-aacodian Button:hover {
  background: #fafafa;
  color: #000;
  border: 1px solid #e9e9e9;
}

.advantageAccodian .accordion-item {
  border: unset;
}

.grivance-aacodian-show p {
  margin-bottom: 10px;
}

.grivance-aacodian-show {
  padding: 10px;
  border: 1px solid #e9e9e9;
}

.advantageAccodian .grivance-aacodian-show {
  padding: unset;
  border: unset;
}

.advantage Button:focus {
  box-shadow: unset;
  background: #fff;
  color: #000;
  border: 1px solid #e9e9e9;
}

.grivance-aacodian Button:focus {
  box-shadow: unset;
  background: #fff;
  color: #000;
  border: 1px solid #e9e9e9;
}

.advantage p {
  border: 1px solid #e9e9e9;
  padding: 10px;
  background: #fff;
  color: #000;
  width: 100%;
  text-align: left;
  border-radius: 0;
}

.advantageAccodian .accordion-item {
  margin-bottom: 20px;
}

.advantage-body {
  border: 1px solid #e9e9e9;
}

.advantage .accordion-item {
  border: unset;
}

.advantage .accordion-button:not(.collapsed) {
  color: unset;
  background: unset;
  box-shadow: unset;
}

.map {
  width: 100%;
  height: 300px;
}

.contact-form {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 20px;
  margin-top: -100px;
  z-index: 99;
  background: #fff;
}

.contact-form-button {
  background: #1268c0;
  border-radius: 5px;
  border: 1px solid #1268c0;
  transition: 0.2s;
}

.contact-form-button:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: #fff;
  color: #1268c0;
}

.contact-bottom-section h5 {
  margin-bottom: 0;
  margin-right: 8px;
}

.contact-bottom-section a {
  color: blue;
  text-decoration: underline;
}

.contact-form input,
.contact-form select {
  border-radius: 0;
  height: 45px;
}

.contact-bottom-section {
  margin-bottom: 0;
}

.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-icon);
}

.accordion-faq .accordion-button:not(.collapsed) {
  color: #fff;
  background: #1268c0;
}

.accordion-faq .accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-icon);
}

.accordion-faq .accordion-button:focus {
  box-shadow: unset;
}

.accordion-faq .faq-btn {
  margin-right: 10px;
  background: #1268c0;
  border-color: #1268c0;
}

.accordion-faq .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-faq .faq-button-link {
  margin-right: 10px;
  background: #1268c0;
  border-color: #1268c0;
  padding: 6px 12px;
  color: #fff;
  border-radius: 5px;
  font-size: 16px;
}

.bv-btn {
  background: #1268c0;
  border-color: #1268c0;
  width: auto;
  border-radius: 0;
}

.bv-table div {
  width: 100%;
  text-align: center;
  border: 1px solid #e4e4e4;
  padding: 15px;
}

.bv-table div p {
  margin-bottom: 0;
}

.bv-table-description {
  text-align: left !important;
}

.mini-table {
  padding: 0 !important;
  border: none !important;
  width: 55% !important;
}

.mini-table h6,
.mini-table p {
  margin: 0;
  padding: 10px;
  border: 1px solid #e4e4e4;
}

.mini-left,
.mini-right {
  border: none !important;
  text-align: left !important;
  padding: 0 !important;
}

.main-bv-table {
  padding-left: 26px;
}

.col-md-1.col-sm-2.col-2.m-0.p-0.d-flex.bv-table {
  font-weight: 800 !important;
}

.col-md-3.col-sm-10.col-10.m-0.p-0.d-flex.bv-table {
  font-weight: 800;
}

.team-image {
  width: 10%;
  border: 1px solid #e5e5e5;
}

.team-information-side {
  width: 90%;
  padding-left: 20px;
  position: relative;
}

.subTitleHead {
  margin-bottom: 30px;
}

.blue {
  color: blue;
  cursor: pointer;
}

.tableHead {
  background: #fafafa;
}

@media (max-width: 1400px) {


  .product img {
    padding-top: 62px;
    padding-bottom: 32px;
    height: 150px;
    width: auto;
  }

  .product h6 {
    font-size: 12px;
  }

  .our-product {
    width: 450px;
  }

  .home-chart canvas {
    padding: 30px !important;
  }

  .chartLables {
    bottom: 20px;
  }
}

@media (max-width: 1200px) {

  .home-header .nav-links {
    font-size: 16px;
  }

  .home-slider .banner-img {
    height: 400px;
    width: auto;
  }

  .home-slider {
    height: auto;
  }

  .LoanJourney {
    display: none;
  }

  .ResponsiveLoanJourney {
    display: block;
  }

  .totalList-new p {
    font-weight: 500;
    font-size: 14px;
  }

  .totalList-new h1 {
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 800;
  }

  .product img {
    padding-top: 62px;
    padding-bottom: 32px;
    height: 150px;
    width: auto;
  }

  .product h6 {
    font-size: 9px;
  }

  .our-product {
    width: 410px;
  }

  .team-image {
    width: 15% !important;
  }

  .team-information-side {
    width: 85% !important;
  }

  .team .team-head-info h6,
  .team .team-head-info p {
    margin-bottom: 0;
  }

  .team .team-head-info p {
    font-size: 10px;
  }
}

@media (max-width: 992px) {
  .product-slider .slick-prev {
    left: -22px;
  }

  .home-header .navbar-nav {
    display: none;
  }

  .nav-side-logo {
    display: block;
  }

  .product-slider .slick-next {
    right: -10px;
  }

  .show.dropdown {
    width: 100% !important;
  }

  .team-position {
    display: grid;
  }

  .respnsive-head-info-show {
    display: none;
  }

  .team-image {
    width: 30% !important;
  }

  .team-information-side {
    width: 120% !important;
  }

  .respnsive-head-info {
    display: block;
  }

  .respnsive-head-info p {
    margin: 0;
  }

  .contact-drop-class {
    align-self: unset;
  }

  .nestLink {
    padding: unset;
  }

  .home-slider .slick-dots {
    bottom: 0px;
  }

  .right-carret {
    right: 10px;
  }

  .menuDropDown button {
    padding: 0px !important;
    margin-left: 0px;
    margin-top: 10px;
  }

  #dropdown-custom-1 {
    background: #fff !important;
    border: none;
    color: #252525;
    font-size: 16px;
    padding: 4px 16px !important;
    font-weight: normal;
    font-family: "Red Hat Display", sans-serif;
    margin-top: 0;
    display: block;
    width: 100%;
    text-align: left;
  }

  .nestedDrop {
    width: auto !important;
    margin-left: 16px !important;
    margin-right: 16px !important;
    margin-top: 0 !important;
  }

  .menuDropDown .dropdown-menu .btn-group {
    width: 100%;
  }

  .menuDropDown {
    width: 100%;
    margin: 0;
  }

  .home-slider .banner-img {
    height: 300px;
    width: auto;
  }

  .contact-us {
    margin-left: 0px !important;
    margin-top: 10px;
  }

  .home-slider {
    height: auto;
    margin-bottom: 0px;
  }

  .testimonial {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .home-main-heading {
    margin-bottom: 30px !important;
    font-size: 20px;
    text-align: center;
  }

  .drop-section {
    margin-right: auto;
  }

  .drop-header {
    background: #1268c0;
  }

  .home-header {
    padding-bottom: 5px;
    padding-top: 5px;
  }

  .home-header .container {
    align-items: center !important;
  }

  .home-header .navbar-logo {
    height: 50px;
  }

  .totalList-new p {
    font-weight: 500;
    font-size: 9px;
  }

  .totalList-new h1 {
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 800;
  }

  .product {
    padding: 20px 5px;
    height: 100px;
    margin-top: 15px;
  }

  .product img {
    padding-top: 0px;
    padding-bottom: 10px;
    height: 45px;
    width: auto;
  }

  .product h6 {
    font-size: 13px;
    margin-bottom: 0;
    padding: 0;
  }

  .testimonial-normal {
    display: none;
  }

  .testimonial-responsive {
    display: block;
  }

  .ResponsiveLoanJourney .slick-dots,
  .testimonial-responsive .slick-dots {
    width: inherit;
    position: unset;
  }

  .chartLables h4 {
    font-size: 12px;
  }

  .lable-box {
    width: 20px !important;
    height: 20px !important;
  }

  .team .team-head-info p {
    font-size: 12px;
  }

  .team .team-head-info h6 {
    font-size: 14px;
  }

  .pluse {
    right: -5px;
  }
}

@media (max-width: 767px) {
  .marginTop-20 {
    margin-top: 20px;
  }

  .choose {
    justify-content: flex-start !important;
  }

  .sm-mt-3 {
    margin-top: 15px;
  }

  .about-imgs {
    margin-bottom: 20px;
    text-align: center;
  }

  .home-slider .banner-img {
    height: 200px;
    width: auto;
  }

  .totalList-new {
    width: 32.33% !important;
  }

  .totalList-new p {
    font-weight: 500;
    font-size: 10px;
  }

  .totalList-new h1 {
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 800;
  }

  .payTotal-new span {
    display: unset;
  }

  .product {
    margin-top: 15px;
  }

  .secondrow {
    display: none;
  }

  .firstRow {
    margin-top: 10px;
  }

  .home-view-more-new {
    display: block !important;
  }

  .view-more-none {
    display: none;
  }

  .our-product .productInfoRight {
    margin-left: 10px !important;
    align-self: center !important;
  }

  .our-product p {
    white-space: nowrap;
    width: 94%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .our-product h4 {
    margin-bottom: 0px !important;
    white-space: nowrap;
    width: 94%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .chartLables {
    left: 15px;
  }

  .pluse {
    right: -5px;
  }

  .contact-form {
    margin-top: unset;
  }

  .lineage-content .about-heading {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .advantage Button {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .our-product {
    width: 256px;
    height: 75px;
    margin-left: 10px;
  }

  .home-main-heading {
    font-size: 18px;
  }

  .home-slider .banner-img {
    height: auto;
    width: 100%;
  }

  .product-slider .slick-track {
    display: flex;
  }

  .product-slider .slick-next,
  .product-slider .slick-prev {
    top: 45%;
  }

  .product-slider .slick-list {
    margin-left: auto;
    width: 80%;
    margin-right: auto;
  }

  .product-slider .slick-next {
    right: 20px;
    z-index: 99;
  }

  .product-slider .slick-list {
    padding: 5px;
  }

  .product-slider .slick-prev {
    left: 16px;
    z-index: 99;
  }

  .product-slider .our-product {
    border-radius: 10px;
  }

  .loan-box {
    width: 93%;
    padding: 11px 11px 11px 40px;
  }

  .loan-box h4 {
    font-size: 18px;
  }

  .loan-box p {
    font-size: 14px;
  }

  .responsive-sub-info {
    font-size: 14px;
  }

  .user-name-responsive {
    font-size: 18px;
    margin-right: 5px;
    margin-top: 0px;
    align-self: center;
    margin-bottom: 0;
    padding: 0;
    font-weight: 700;
  }

  .testimonial-right {
    padding: 10px;
    text-align: center;
  }

  .our-product img {
    width: 40%;
    height: 100%;
    align-self: center;
  }

  .our-product {
    padding: 10px 5px !important;
    margin-left: 10px !important;
    margin-right: 10px;
  }

  .our-product p {
    font-size: 10px;
    white-space: nowrap;
    width: 94%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .our-product h4 {
    font-size: 10px;
    margin-bottom: 0px !important;
    white-space: nowrap;
    width: 94%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .productInfoRight {
    width: 60% !important;
  }

  .our-product .home-view-more {
    font-size: 12px;
    padding: 5px 10px;
    margin-top: 10px !important;
  }

  .product-slider .slick-next:before {
    color: #7f7f7f;
    padding: 2px;
  }


  .product-slider .slick-prev:before {
    color: #7f7f7f;
    padding: 2px;
  }

  .home-view-more {
    margin-top: 20px !important;
  }

  .range-view h4 {
    font-size: 14px;
  }

  .pluse {
    right: -10px;
  }

  .heading-text {
    font-size: 20px;
  }

  .copyRight {
    font-size: 13px;
  }

  .user-post-name {
    justify-content: center;
  }
}
/* =============================Coman error Message ==================================== */

.errorMessage {
  background-color: rgb(252, 245, 247);
  padding: 0.5rem;
  border-color: rgb(202, 55, 127);
  border-left: medium solid rgb(202, 55, 127);
  color: rgb(141, 39, 59);
  margin-bottom: 1rem;
}

/* =============================End Coman error Message ==================================== */

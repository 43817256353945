.logOutInner {
  height: 120px;
  width: 450px;
  background: rgb(255, 255, 255);
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.promptModalBg {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 9999;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logOutInnerBG {
  height: 165px;
  width: 700px;
  background: rgb(255, 255, 255);
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.commonModalTitle {
  /* font-weight: 550; */
  font-size: 17px;
  padding: 5px 18px 0px;
  height: 68px;
  margin-bottom: 0px;
  align-items: center;
}

.njSocialHandlesModalTitle {
  font-size: 16px;
  padding: 5px 10px 0px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 0px;
  align-items: center;
  color: rgba(255,0,0);
}

.njsocialHandlesFont {
  font-family: "Red Hat Display",sans-serif;
  font-variant: tabular-nums;
  font-feature-settings: "tnum","tnum";
}

.securityModal{
  max-width: 80%; /* Set the width to 80% */
}